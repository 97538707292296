import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import * as z from 'zod';

import { PasswordInput } from '@/components/password-imput';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { useUserContext } from '@/context/user';

import { useSignIn } from '..';

const formSchema = z.object({
  email: z
    .string()
    .min(2)
    .superRefine((val, ctx) => {
      if (val.includes('=>')) {
        return;
      }
      if (val.length > 50 || val.length < 2) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: 50,
          type: 'string',
          inclusive: true,
          message: `String must contain at most 50 characters`,
        });
      }
    }),
  password: z.string().min(8).max(50),
});

export const SignInForm = () => {
  const { setUser } = useUserContext();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      email: '',
      password: '',
    },
  });

  const { mutate, data, isPending, error } = useSignIn();

  const { toast, dismiss } = useToast();

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data, setUser]);

  useEffect(() => {
    if (error) {
      toast({
        description:
          'Error: ' + (error.response?.data.message || error.message),
        variant: 'destructive',
      });
    } else {
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutate(values);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter Email Address"
                  autoComplete="email"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="mb-1.5">
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <PasswordInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Link
            to="/forgot-password"
            className="text-sm text-primary transition-opacity hover:opacity-80"
          >
            Forgot password?
          </Link>
        </div>

        <Button type="submit" className="w-full" disabled={isPending}>
          {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Sign In
        </Button>

        <p className="text-center text-sm text-muted-foreground">
          Don't have account{' '}
          <Link
            to="/sign-up"
            className="text-sm text-primary transition-opacity hover:opacity-80"
          >
            Sign Up
          </Link>
        </p>
      </form>
    </Form>
  );
};
